@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Scope One";
    src: url('./font/ScopeOne-Regular.ttf');
}

body {
    margin: 0;
   
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fafafa;
    color: #636262;
    font-family: "Scope One", serif;
    font-size: 13pt;
    font-weight: 300;
    line-height: 1.65;
    letter-spacing: 1px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
.linkBlue:hover{
    color: #3498db;
}
.linkBlue{
    transition: 0.3s;
}
.link{
    width: 100%;
}

#banner{
    height: 100vh !important;
    background-image: url('./img/banner.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
#inner {
    display: block;
    background-color: rgb(0,0,0,0.5);
    height: 100%;
    left: 0;
    /* opacity: 0.65; */
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}
#footer{
    padding: 2em 2em 2em 2em;
    background-color: #e3e3e3;
}
.btn{
    @apply bg-blue-500 py-2 px-5 rounded text-white duration-300;
}
.btn:hover{
    @apply  bg-blue-600 font-bold ;
}
.MuiSnackbar-root{
    border: 1px solid black !important;
    border-radius: 5px !important;
}
.article{
    @apply transition duration-500 ease-in-out transform !important;
}
.article:hover {
    @apply -translate-y-1 scale-105 !important;
}